import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { Layout, Slice, Form } from 'components';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import queryString from 'query-string';

const IS_BROWSER = typeof window !== 'undefined';

const Page = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { page } = data;
  const { data: pageData, uid } = page;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
  } = pageData;
  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
  };

  const itemIndexes = [];
  const itemsWithTwoColsTextInARow = [];

  sliceData?.forEach((item, index) => {
    if (item?.slice_type === 'two_cols_text') {
      itemIndexes.push(index);
    }
  });

  // Handle drapeoverlap issue
  for (let i = 0; i < itemIndexes?.length; i++) {
    if (itemIndexes?.includes(itemIndexes[i] + 1)) {
      itemsWithTwoColsTextInARow.push(itemIndexes[i]);
    }
  }

  const isTwoDrapes = sliceData[itemsWithTwoColsTextInARow[0]]?.id;

  const scrollToElement = (scrollTo) => {
    if (!scrollTo) return null;
    // remove trailing slash from querystring (form/ becomes form)
    const strippedScrollTo = scrollTo.replace(/\/$/, '');
    const element = document.getElementById(strippedScrollTo);
    if (!element) return null;
    return scrollIntoView(element, {
      behavior: 'smooth',
      block: 'start',
      duration: 1800,
    });
  };

  useEffect(() => {
    const queryStrings = location && location.search ? queryString.parse(location.search) : {};
    const { scrollTo } = queryStrings;

    if (scrollTo) scrollToElement(scrollTo);
  }, [location]);

  return (
    <Layout location={location} seo={seo}>
      {sliceData.map((slice) => (
        <Slice key={slice.id} data={slice} uid={uid} isTwoDrapes={isTwoDrapes} />
      ))}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        ogImage: open_graph_image {
          url
        }
        schema {
          text
        }
        body {
          ...FullWidthVideoPageFragment
          ...LargeImagePageFragment
          ...QuotePageFragment
          ...TwoColImageGridPageFragment
          ...TwoColsImageTextPageFragment
          ...TwoColsTextPageFragment
          ...TwoColsFeatureRoomImageFragment
          ...ImageCarouselFragment
          ...DownloadAccordionFragment
          ...InteractiveMapPageFragment
          ...ContactFormFragment
          ...DiagramFragment
          ...LargeVideoFragment
          ...WysiwygFragment
          ...MultiColImageGridPageFragment
          ...AnchorIdFragment
        }
      }
    }
  }
`;
